export default [
  {
    title: "Home",
    url: "#home"
  },
  {
    title: "Services",
    url: "#services"
  },
  {
    title: "Product",
    url: "#products"
  },
  {
    title: "Contact",
    url: "#contact"
  }
]
