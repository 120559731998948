import React from "react"
import * as BS from "react-bootstrap";
import Layout from "../layouts/layout"

import styles from "./index.module.scss"
import AroundMePhone from "../images/AroundMePhone.png"
import GetInTouch from "../images/GetInTouch.jpg"
import SDLC from "../images/SDLC.jpg"
import BDD from "../images/BDD.png"

export default () => (
  <Layout>
    <header id="home" className={`${styles.master} master text-white text-center`}>
      <BS.Container>
        <BS.Row>
          <div className="col-xl-9 mx-auto">
            <h1 className="mb-5">Taking responsibility over solving your product development problems...</h1>
          </div>
        </BS.Row>
      </BS.Container>
    </header>
    <section id="services" className={`${styles.services}`}>
      <BS.Container className={styles.icons}>
        <BS.Row>
          <BS.Col lg="4">
            <div className={`${styles.item} mb-5 mb-lg-0 mb-lg-3`}>
              <img className={`${styles.icon} m-auto d-flex`} src={SDLC} alt="Full Software Development Life Cycle"/>
              <h3>Full Software Development Life Cycle</h3>
              <p className="lead mb-0">We support you from gathering business requirements up to product deployment and maintenance.</p>
            </div>
          </BS.Col>
          <BS.Col lg="4">
            <div className={`${styles.item} mb-5 mb-lg-0 mb-lg-3`}>
              <div className={`${styles.icon} d-flex`}>
                <span className="oi oi-cloud m-auto text-primary" title="cloud" aria-hidden="true"></span>
              </div>
              <h3>Cloud-native solutions</h3>
              <p className="lead mb-0">We design and implement cloud solution that can easily scale with your business.</p>
            </div>
          </BS.Col>
          <BS.Col lg="4">
            <div className={`${styles.item} mb-5 mb-lg-0 mb-lg-3`}>
              <img className={`${styles.icon} m-auto d-flex`} src={BDD} alt="Automated testing"/>
              <h3>Automated Testing</h3>
              <p className="lead mb-0">We lower the effort and prevent regression by introducing efficient automated testing in product development.</p>
            </div>
          </BS.Col>
        </BS.Row>
      </BS.Container>
    </section>
    <section id="products" className="products bg-light">
      <BS.Container>
        <BS.Row>
          <BS.Col lg="7" className="mb-4 mb-lg-0">
            <h3>AroundMe</h3>
            <p className="lead">Explore the virtual world around you...</p>
            <p>Did you ever wonder how does the virtual world around you look like? Use the AroundMe app to virtually interact with your surrounding...</p>
            <a className="btn btn-primary" href="https://aroundme.impruvit.net">Find out more</a>
          </BS.Col>
          <BS.Col lg="5">
            <img className={`${styles.AroundMe} mt-4 mt-lg-0 mx-auto d-block`} src={AroundMePhone} alt="AroundMe App"/>
          </BS.Col>
        </BS.Row>
      </BS.Container>
    </section>
    <section id="contact" className={`${styles.contact}`}>
      <BS.Container>
        <BS.Row>
        </BS.Row>
        <BS.Row>
          <BS.Col lg="5">
            <img className="img-fluid rounded mb-4 mb-lg-0" src={GetInTouch} alt="Get in touch"/>
          </BS.Col>
          <BS.Col lg="7">
            <h2>Get in touch</h2>
            <p>Please drop us an email or give us a call. We will work together to understand your needs and how can we help you.</p>
            <p><span className={`oi oi-envelope-closed ${styles.icon}`} title="email" aria-hidden="true"></span>&nbsp;<a href="mailto:burgera@impruvit.net">burgera@impruvit.net</a><br/>
            <span className={`oi oi-phone ${styles.icon}`} title="phone" aria-hidden="true"></span>&nbsp;<a href="tel:+41789438180">+41 78 943 81 80</a><br/>
            <span className={`oi oi-map-marker ${styles.icon}`} title="address" aria-hidden="true"></span>&nbsp;Feldmatt 5, CH-6030 Ebikon, Switzerland</p>
          </BS.Col>
        </BS.Row>
      </BS.Container>
    </section>
  </Layout>
)
