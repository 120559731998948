import React from "react"
import { Helmet } from "react-helmet";
import NavBar from "../components/navbar"
import Footer from "../components/footer"

import "./layout.scss"

const siteDescription = "Site description";
const siteKeywords = [];

export default (props) => (
  <React.Fragment>
    <Helmet defaultTitle="ImpruvIT"
            titleTemplate="%s | ImpruvIT">
      <title>{props.title}</title>
		  <meta name="description" content={ props.description || siteDescription } />
  	  <meta name="keywords" content={ siteKeywords.concat(props.keywords || []).join() }/>
		  <meta name="author" content="Andrej Burger"/>

      <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
  		<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous"/>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/open-iconic/1.1.1/font/css/open-iconic-bootstrap.min.css" integrity="sha256-BJ/G+e+y7bQdrYkS2RBTyNfBHpA9IuGaPmf9htub5MQ=" crossOrigin="anonymous" />
    </Helmet>
    
    <NavBar title={props.title} description={props.description} keywords={props.keywords} />
    {props.children}
    <Footer />

    <script src="https://code.jquery.com/jquery-3.4.1.slim.min.js" integrity="sha256-pasqAKBDmFT4eHoN2ndd6lN370kFiGUFyTiUHWhU7k8=" crossOrigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossOrigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossOrigin="anonymous"></script>
  </React.Fragment>
)
