import React from "react"
import * as BS from "react-bootstrap";

import menu from "../data/menu";
import Logo from "../images/logo_sm.png"

export default () => (
  <BS.Navbar variant="light" bg="light" expand="sm" fixed="top">
    <BS.Container>
      <BS.Navbar.Brand href="#home"><img src={Logo} alt="ImpruvIT" className="logo" /></BS.Navbar.Brand>
      <BS.Navbar.Toggle aria-controls="basic-navbar-nav" />
      <BS.Navbar.Collapse id="basic-navbar-nav">
        <BS.Nav className="ml-auto bg-light">
          { menu.map((item, index) => { return (
            <BS.Nav.Link key={index} href={item.url} active="false">
              {item.title}
              <span className="sr-only">(current)</span>
            </BS.Nav.Link>
            )})
          }
        </BS.Nav>
      </BS.Navbar.Collapse>
    </BS.Container>
  </BS.Navbar>
)